<template>
  <section id="hero">
    <v-img
      :height="minHeight"
      :src="require('@/assets/004.jpeg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .5), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mr-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-subheading
            v-if="$vuetify.breakpoint.mdAndUp"
            weight="regular"
            title="Разработка комплексных интегрированных бизнес-систем под индивидуальные потребности заказчика."
          />

          <base-heading
            size="text-h3"
            weight="medium"
            class="text-left"
          >
            <base-img
              :src="require(`@/assets/logo-2.png`)"
              contain
              :max-width="maxWidthImg"
              width="100%"
              class="mr-auto mb-3"
            />
            cтудия разработки программного обеспечения
          </base-heading>

          <base-body v-if="!$vuetify.breakpoint.mdAndUp">
            Разработка комплексных интегрированных бизнес-систем под индивидуальные потребности заказчика.
          </base-body>
          <!--          <div-->
          <!--            v-if="1==0"-->
          <!--            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"-->
          <!--            class="d-flex flex-wrap"-->
          <!--          >-->
          <!--            <base-btn>-->
          <!--              Discover More-->
          <!--            </base-btn>-->
          <!--            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>-->
          <!--            <base-btn-->
          <!--              :ripple="false"-->
          <!--              class="pa-1"-->
          <!--              height="auto"-->
          <!--              text-->
          <!--            >-->
          <!--              Get Started Now-->
          <!--            </base-btn>-->
          <!--          </div>-->
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',
    components: {},
    provide: {
      theme: { isDark: true },
    },
    computed: {
      maxWidthImg () {
        return this.$vuetify.breakpoint.mdAndUp ? '290px' : '190px'
      },
      minHeight () {
        const height = '100vh' // this.$vuetify.breakpoint.mdAndUp ? '100vh' : '100vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
